import { Formik, FormikHelpers } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Input } from 'components/controls/Input';
import { faLock } from '@fortawesome/pro-light-svg-icons/faLock';
import { faAt } from '@fortawesome/pro-light-svg-icons/faAt';
import Link from 'next/link';
import { Button, LinkButton } from 'components/controls/Button';
import styles from './LoginForm.module.css';
import { useUser } from 'providers/UserProvider';
import { handleFormError } from 'helpers/handleFormError';
import { useRouter } from 'next/router';
import { Pages } from 'constants/pages';
import { InputContainer } from 'components/controls/InputContainer';
import { Notification } from 'components/ui/Notification';
import { InputError } from 'components/controls/InputError';
import { object, string } from 'yup';

interface FormValues {
  email: string;
  password: string;
}

const validationSchema = object({
  email: string().email('Vul een geldig e-mailadres in').required('Verplicht veld'),
  password: string().required('Verplicht veld').min(8),
});

const initialValues = { email: '', password: '' };

export const LoginForm = () => {
  const { login } = useUser();
  const [error, setError] = useState<string>();
  const { replace: routerReplace, prefetch: routerPrefetch, query: routerQuery, asPath: routerPath } = useRouter();

  const onSubmit = useCallback(
    async (values: FormValues, { setSubmitting }: FormikHelpers<FormValues>) => {
      setSubmitting(true);
      try {
        setError(undefined);
        await login({
          email: values.email,
          password: values.password,
        });

        if (!!routerQuery?.response_type && routerQuery?.client_id) {
          routerReplace(`${Pages.LoginRedirect}?${routerPath.split('?')[1]}`);
          return;
        }

        routerReplace(Pages.Home);
      } catch (_error) {
        setSubmitting(false);
        handleFormError(_error, (message, code) => {
          if (code === 401) {
            setError('Ongeldige combinatie van gebruikersnaam en wachtwoord.');
            return;
          }
          setError(message);
        });
      }
    },
    [login, routerPath, routerQuery?.client_id, routerQuery?.response_type, routerReplace]
  );

  useEffect(() => {
    routerPrefetch(Pages.Home);
  }, [routerPrefetch]);

  return (
    <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
        <form onSubmit={handleSubmit}>
          {error ? <Notification type="error">{error}</Notification> : undefined}
          <InputContainer>
            <Input
              error={!!(errors.email && touched.email)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.email}
              type="email"
              name="email"
              icon={faAt}
              label="E-mailadres"
            />
            {errors.email && touched.email ? <InputError>{errors.email}</InputError> : undefined}
          </InputContainer>
          <InputContainer>
            <Input
              error={!!(errors.password && touched.password)}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
              type="password"
              name="password"
              icon={faLock}
              label="Wachtwoord"
            />
            <div className={styles.passwordErrorContainer}>
              {errors.password && touched.password ? <InputError>{errors.password}</InputError> : undefined}
              <Link className={styles.forgotPassword} href={Pages.ForgotPassword}>
                Wachtwoord vergeten
              </Link>
            </div>
          </InputContainer>

          <div className={styles.buttonContainer}>
            <Button type="submit" loading={isSubmitting} label="Inloggen" />
            <LinkButton secondary href={Pages.Register} label="Maak een account" />
          </div>
        </form>
      )}
    </Formik>
  );
};
