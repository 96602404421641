import { Subscription } from 'helpers/types';

export const Subscriptions: {
  [subscriptionPrefix: string]: Subscription;
} = {
  VSA: {
    code: 'VSA',
    name: 'Zachte lenzen',
    subTitle: 'Visser Serviceabonnement A',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      '40 – 60 % korting op contactlenzen',
      '10 – 20 % korting op vloeistoffen en bijartikelen',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: 'U ontvangt halfjaarlijks van ons een controleoproep. Maar uw lenzen worden pas besteld wanneer u aangeeft aan vervanging toe te zijn.',
  },
  VSB: {
    code: 'VSB',
    name: 'Zachte lenzen',
    subTitle: 'Visser Serviceabonnement B',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      'Inclusief contactlenzen*',
      '40 – 60 % korting op aanschaf van extra lenzen**',
      '10 – 20 % korting op vloeistoffen en bijartikelen',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: `U ontvangt halfjaarlijks van ons een controleoproep. Maar uw lenzen worden pas besteld wanneer u aangeeft aan vervanging toe te zijn.\n 
    *Inclusief 12 paar maandlenzen, 24 paar tweewekelijkse lenzen of 365 paar daglenzen per jaar. Het maandbedrag is afhankelijk van welke lenzen u afneemt.
    **Indien u meer dan de standaard hoeveelheid lenzen wilt afnemen krijgt u 40 – 60 % korting.`,
  },
  VSC: {
    code: 'VSC',
    name: 'Vormstabiele lenzen',
    subTitle: 'Visser Serviceabonnement C',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      '40 – 60 % korting op contactlenzen',
      '10 – 20 % korting op vloeistoffen en bijartikelen',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: 'U ontvangt jaarlijks van ons een controleoproep. Maar uw lenzen worden pas besteld na controle en overleg met uw specialist of wanneer u aangeeft aan vervanging toe te zijn.',
  },
  VSD: {
    code: 'VSD',
    name: 'Vormstabiele lenzen',
    subTitle: 'Visser Serviceabonnement D',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      'Inclusief contactlenzen*',
      '50 – 60 % korting op aanschaf van extra lenzen**',
      '10 – 20 % korting op vloeistoffen en bijartikelen ',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: `U ontvangt jaarlijks van ons een controleoproep. Na de controle bij de specialist worden uw lenzen besteld.\n
    * Inclusief 1 paar vormstabiele lenzen per jaar. Het maandbedrag is afhankelijk van welke lenzen u afneemt.
    **Indien u meer dan de standaard hoeveelheid lenzen wilt afnemen krijgt u 50 – 60 % korting. `,
  },
  VSM: {
    code: 'VSM',
    name: 'Lenzen op medische indicatie',
    subTitle: 'Visser Medisch abonnement',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      'Visser Contactlenzen draagt zorg voor de declaratie bij uw zorgverzekeraar',
      '10 – 20 % korting op vloeistoffen en bijartikelen',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: `U ontvangt van ons met regelmaat een controleoproep. In overleg met de specialist
    worden uw lenzen indien nodig vervangen volgens de richtlijnen van uw
    zorgverzekeraar.
    `,
  },
  FV: {
    code: 'FV',
    name: 'Freeview',
    subTitle: 'Freeview abonnement',
    text: 'Voordelen:',
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      'Inclusief aanschaf van jaarlijks nieuwe lenzen*',
      '40 – 60 % korting op aanschaf van nieuwe lenzen bij verlies of breuk**',
      '10 – 20 % korting op vloeistoffen en bijartikelen',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: `U krijgt twee keer per jaar een controle. Uw lenzen worden één keer per jaar
    vervangen.\n\n* Inclusief 1 paar Freeview lenzen per jaar. Het maandbedrag is afhankelijk van welke lenzen u afneemt. De borg van de Freeview lenzen bedraagt €75,- per lens. De borg ontvangt u terug bij beëindiging van het abonnement en na het inleveren van de lens/lenzen binnen 3 maanden.
    **Indien u bij verlies of breuk nieuwe lenzen wilt afnemen, krijgt u 40 – 60 % korting.`,
  },
  PVS: {
    code: 'PVS',
    name: 'Visser Serviceabonnement',
    subTitle: 'Persoonlijk Visser Serviceabonnement',
    text: `Persoonlijk Visser Serviceabonnement voor medewerkers van Visser Contactlenzen, familieleden en medewerkers van de poli oogheelkunde.\n 
    Voordelen:`,
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      '20% extra korting op de VSA / VSC abonnementsprijs van contactlenzen',
      '20% extra korting op de abonnementsprijs van vloeistoffen en bijproducten.',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: 'U ontvangt van ons een controleoproep, maar uw lenzen worden pas besteld wanneer u aangeeft aan vervanging toe te zijn.',
  },
  A: {
    code: 'PVS',
    name: 'Visser Serviceabonnement',
    subTitle: 'Persoonlijk Visser Serviceabonnement',
    text: `Persoonlijk Visser Serviceabonnement voor medewerkers van Visser Contactlenzen, familieleden en medewerkers van de poli oogheelkunde.\n 
    Voordelen:`,
    lines: [
      'Onbeperkt alle contactlens-gerelateerde oogzorg (controles en aanmetingen)',
      '20% extra korting op de VSA / VSC abonnementsprijs van contactlenzen',
      '20% extra korting op de abonnementsprijs van vloeistoffen en bijproducten.',
      'Gratis verzending bij bestellingen van boven de €30,-',
    ],
    note: 'U ontvangt van ons een controleoproep, maar uw lenzen worden pas besteld wanneer u aangeeft aan vervanging toe te zijn.',
  },
};
