import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  loginTokens,
  activateAccount,
  updateCustomer as updateCustomerApi,
  useMe,
  useCustomers,
  useCustomer,
} from 'helpers/api';
import { removeTokens, saveTokens, useTokens } from 'providers/TokenProvider';
import { useQueryClient } from '@tanstack/react-query';
import { Customer_customer_portal_customer_list } from 'generated/api/models/Customer_customer_portal_customer_list';
import { Customer_customer_portal_customer_view } from 'generated/api/models/Customer_customer_portal_customer_view';
import { CustomerPortalJWTAuth } from 'generated/api/models/CustomerPortalJWTAuth';
import { Activation } from 'generated/api/models/Activation';
import { Subscriptions } from 'constants/subscriptions';
import { Subscription } from 'helpers/types';
import { CustomerPortalUser_user_view_self } from 'generated/api';

export interface UserContextType {
  isLoadingUser: boolean;
  login: (values: CustomerPortalJWTAuth) => Promise<void>;
  activate: (values: Activation) => Promise<void>;
  logout: () => void;
  user?: CustomerPortalUser_user_view_self;
  customers?: Customer_customer_portal_customer_list[];
  selectedCustomer?: Customer_customer_portal_customer_view;
  setSelectedCustomerCode: (code: string) => void;
  updateCustomer: (data: { code: string; phoneNumber: string }) => Promise<void>;
  subscriptionInfo: Subscription | undefined;
}

const initialState = {
  isLoadingUser: true,
  // eslint-disable-next-line unicorn/no-useless-undefined
  login: async () => undefined,
  // eslint-disable-next-line unicorn/no-useless-undefined
  activate: async () => undefined,
  logout: async () => {
    // init
  },
  user: undefined,
  customers: undefined,
  selectedCustomer: undefined,
  // eslint-disable-next-line unicorn/no-useless-undefined
  setSelectedCustomerCode: () => undefined,
  // eslint-disable-next-line unicorn/no-useless-undefined
  updateCustomer: async () => undefined,
  subscriptionInfo: undefined,
};

const UserContext = createContext<UserContextType>(initialState);
const SESSION_STORAGE_CUSTOMER_CODE = 'VC_CUSTOMER_CODE';

interface Properties {
  children: ReactNode;
}

export const UserProvider = ({ children }: Properties) => {
  const [selectedCustomerCode, setSelectedCustomerCode] = useState<string | undefined>();
  const [selectedCustomer, setSelectedCustomer] = useState<Customer_customer_portal_customer_view | undefined>(
    undefined
  );
  const tokens = useTokens();
  const hasTokens = !!tokens?.accessToken;

  const queryClient = useQueryClient();
  const meQuery = useMe(hasTokens);
  const customersQuery = useCustomers(hasTokens);
  const customerQuery = useCustomer(selectedCustomerCode);
  const queryResults = useMemo(
    () => [meQuery, customersQuery, customerQuery],
    [customerQuery, customersQuery, meQuery]
  );

  const hasError = queryResults.some((query) => query.status === 'error');
  const isLoading =
    tokens.loading || (hasTokens && !hasError && queryResults.some((query) => query.status === 'loading'));

  const user = meQuery.data;
  const customers = customersQuery.data;
  const selectedCustomerResponse = customerQuery.data;

  useEffect(() => {
    setSelectedCustomer(selectedCustomerResponse);
  }, [selectedCustomerResponse]);

  const setSelectedCustomerCodeCallback = useCallback((customerCode?: string) => {
    setSelectedCustomerCode(customerCode);
    if (customerCode) {
      window.sessionStorage.setItem(SESSION_STORAGE_CUSTOMER_CODE, customerCode || '');
    } else {
      window.sessionStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (selectedCustomerCode || !customers) {
      return;
    }

    const storedCustomerCode = window.sessionStorage.getItem(SESSION_STORAGE_CUSTOMER_CODE);

    if (storedCustomerCode && customers.some((customer) => customer.code === storedCustomerCode)) {
      return setSelectedCustomerCodeCallback(storedCustomerCode);
    }

    if (customers.length > 0) {
      return setSelectedCustomerCodeCallback(customers[0].code);
    }
  }, [customers, selectedCustomerCode, setSelectedCustomerCodeCallback]);

  const refetchAll = useCallback(() => {
    meQuery.refetch();
    customersQuery.refetch();
  }, [customersQuery, meQuery]);

  const login = useCallback(
    async (data: CustomerPortalJWTAuth) => {
      const response = await loginTokens(data);

      if (response.token && response.refresh_token) {
        saveTokens(response.token, response.refresh_token);
      }
      refetchAll();
    },
    [refetchAll]
  );

  const logout = useCallback(() => {
    setSelectedCustomerCodeCallback(undefined);
    removeTokens();
    queryClient.resetQueries();
  }, [queryClient, setSelectedCustomerCodeCallback]);

  const activate = useCallback(
    async (data: Activation) => {
      const response = await activateAccount(data);

      if (response.token && response.refresh_token) {
        saveTokens(response.token, response.refresh_token);
      }
      refetchAll();
    },
    [refetchAll]
  );

  const updateCustomer = useCallback(
    async (data: { code: string; phoneNumber: string }) => {
      const updatedCustomer = await updateCustomerApi(data);
      if (updatedCustomer.code === selectedCustomer?.code) {
        customerQuery.refetch();
      }
    },
    [customerQuery, selectedCustomer?.code]
  );

  const subscription = useMemo(() => {
    if (!selectedCustomer) {
      return '';
    }
    return (selectedCustomer.subscriptions || []).find((subscription) => subscription.current === true)?.type || '';
  }, [selectedCustomer]);

  const subscriptionInfo: Subscription | undefined = useMemo(
    () => {
      const validASubscriptions = ['A-FAMILIE', 'A-PERSONEEL', 'A-POLI'];
      if (subscription?.startsWith('A-') && !validASubscriptions.some((prefix) => subscription.startsWith(prefix))) {
        return undefined;
      }

      return Subscriptions[subscription.split('-').shift() || ''];
    },
    [subscription]
  );

  const value = useMemo(
    () => ({
      isLoadingUser: isLoading,
      login,
      logout,
      activate,
      user,
      customers,
      selectedCustomer,
      setSelectedCustomerCode: setSelectedCustomerCodeCallback,
      updateCustomer,
      subscriptionInfo,
    }),
    [
      isLoading,
      login,
      logout,
      activate,
      user,
      customers,
      selectedCustomer,
      setSelectedCustomerCodeCallback,
      updateCustomer,
      subscriptionInfo,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => useContext(UserContext);
